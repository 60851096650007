<template>
  <div>
    <NavBar :title="'基础信息'"></NavBar>
    <van-cell
      title="等级"
      is-link
      :value="user.title"
      @click="edit('等级', 'title')"
    />
    <van-cell
      title="代理商姓名"
      is-link
      :value="user.desc"
      @click="edit('代理商姓名', 'name')"
    />
    <van-cell
      title="身份证号"
      is-link
      :value="address"
      @click="edit('身份证号', 'card_id')"
    />
    <van-cell
      title="手机号"
      is-link
      :value="user.address"
      @click="edit('手机号', 'phone')"
    />
    <van-cell
      title="联系地址"
      is-link
      :value="user.name"
      @click="edit('联系地址', 'address')"
    />
    <van-cell
      title="保证金"
      is-link
      :value="user.phone"
      @click="edit('保证金', 'bond_money')"
    />
    <!-- round -->
    <van-popup
      v-model:show="show"
      position="bottom"
      :style="{ height: '30%' }"
      :close-on-click-overlay="false"
    >
      <div class="from">
        <h1>{{ title }}</h1>
        <textarea rows="8" v-model="editFrom[type]"></textarea>
        <div class="btn">
          <button @click="this.show = false">取消</button
          ><button @click="confirm">确定</button>
        </div>
      </div>
    </van-popup>
    <Address
      v-if="type === 'address1'"
      :city="user.county"
      @confirm="confirmAddress"
    />
  </div>
</template>
<script>
import { Row, Popup, Icon, Cell, CellGroup } from 'vant'
import Address from '../../components/Address/index.vue'
export default {
  name: 'MyInfo',
  components: {
    [Row.name]: Row,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [Popup.name]: Popup,
    [CellGroup.name]: CellGroup,
    Address,
  },
  data() {
    return {
      address: '',
      show: false,
      user: this.$state.user,
      editFrom: this.$state.user,
      type: '',
    }
  },
  methods: {
    confirmAddress(res) {
      if (res && res !== null && res.length === 3) {
        this.editFrom.province = res[0].code
        this.editFrom.city = res[1].code
        this.editFrom.county = res[2].code
        this.address = res[0].name + '-' + res[1].name + '-' + res[2].name
        console.log(this.address)

        this.confirm()
      } else {
        this.$notify('地址选择错误')
      }
      this.type = ''
    },
    edit(name, type) {
      if (type === 'agent.name' || type === 'agent.phone') {
        return
      }
      this.type = type
      if (type !== 'address1') {
        this.show = true
        this.title = '修改' + name
      }
    },
    confirm() {
      this.$http({
        url: '/merchant/userEdit',
        method: 'post',
        data: this.$http.adornData(this.editFrom),
      }).then(() => {
        this.show = false
        this.$notify({ type: 'success', message: '修改成功' })
        this.user = this.editFrom
        this.$state.user = this.editFrom
      })
    },
  },
}
</script>
<style lang="less" scoped>
.logo {
  width: 50px;
  height: 50px;
}
.from {
  text-align: center;
  overflow: hidden;
  height: 100%;
  h1 {
    margin: 0 auto;
    padding: 10px 0 15px 0;
    font-size: 20px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.85);
    line-height: 35px;
  }
  textarea {
    width: 80%;
    height: 70px;
    background: #f3f3f3;
    border-radius: 8px 8px 8px 8px;
    border: none;
  }
  .btn {
    padding: 15px 0;
  }
  button {
    border: none;
    width: 38%;
    height: 40px;
    // margin-top: 32px;
  }
  button:nth-child(1) {
    color: rgba(0, 0, 0, 0.4);
    // width: 151px;
    // height: 44px;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    padding-left: 10px;
    margin-right: 10px;
    // border: 1px solid rgba(0, 0, 0, 0.4);
  }
  button:nth-child(2) {
    // width: 152px;
    // height: 44px;
    background: linear-gradient(235deg, #f56f4d 0%, #f35450 100%);
    border-radius: 8px 8px 8px 8px;
    color: #ffffff;
    opacity: 1;
  }
}
</style>
